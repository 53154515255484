define(['deliveryInfoSearch'], (deliveryInfoSearch) => () => {
  const comp = {};

  comp.init = function(el) {
    comp.search = new deliveryInfoSearch('internationalDelivery_');
    comp.search.init(el);
  };

  return comp;
});
